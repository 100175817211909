import { Select } from "antd";
import React, { useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { ProjectQuestionType } from "../../../../types";
import { setLoading } from "../../../../redux/slices/loadingSlice";
import { handleCheckAxiosError } from "../../../../utils";
import { setAccount } from "../../../../redux/slices/accountSlice";
import { projectAPI } from "../../../../services";
import { handleMessage } from "../../../commons/Message";
import { useDispatch } from "react-redux";

type Props = {
  editedQuestion: ProjectQuestionType;
  onCancel: () => void;
  onSuccess: (newQuestion: ProjectQuestionType) => void;
};

const UpdateQuestion: React.FC<Props> = ({ editedQuestion, onCancel, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    watch,
  } = useForm<ProjectQuestionType>();
  const formValuesState = watch();

  const onSubmit: SubmitHandler<ProjectQuestionType> = async (value) => {
    try {
      dispatch(setLoading(true));
      const resultUpdateQuestionApi = await projectAPI.updateQuestion({
        ...editedQuestion,
        ...value,
      });
      if (resultUpdateQuestionApi.data.result) {
        onSuccess(resultUpdateQuestionApi.data.data);
        handleMessage("success", resultUpdateQuestionApi.data.msg);
      } else {
        handleMessage("error", resultUpdateQuestionApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (editedQuestion) {
      setValue("questionType", editedQuestion.questionType);
      setValue("question", editedQuestion.question);
      setValue("active", editedQuestion.active);
      setValue("required", editedQuestion.required);
      setValue("options", editedQuestion.options);
      register("options", {
        validate: {
          required: (value) => {
            if (formValuesState.questionType !== "INPUT" && value.length === 0) {
              return "Vui lòng nhập Trả lời!";
            } else return true;
          },
        },
      });
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedQuestion]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="mb-3">
        <div className="fw-bold fs-5 mb-2">Loại câu hỏi:</div>
        <div className="fs-5 text-black text-opacity-75">
          <Select
            value={`${formValuesState.questionType}`}
            className="w-100"
            options={["RADIO", "CHECKBOX", "SELECT", "INPUT"].map((item) => ({
              label: item,
              value: item,
            }))}
            onChange={(value) => setValue("questionType", value as "RADIO" | "CHECKBOX" | "SELECT" | "INPUT")}
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="fw-bold fs-5 mb-2">Câu hỏi:</div>
        <div className="fs-5 text-black text-opacity-75">
          <input
            type="text"
            className={`form-control form-control-lg ${errors.question && "border-danger"}`}
            placeholder="Câu hỏi"
            {...register("question", {
              required: "Vui lòng nhập Câu hỏi!",
            })}
            onBlur={() => setValue("question", formValuesState.question.trim())}
          />
          {errors.question && (
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
              {errors.question.message}
            </div>
          )}
        </div>
      </div>
      {formValuesState.questionType !== "INPUT" && (
        <div className="mb-3">
          <div className="fw-bold fs-5 mb-2">Trả lời:</div>
          <div className="fs-5">
            <Select
              status={errors.options ? "error" : ""}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Trả lời"
              value={formValuesState.options}
              onChange={(value) => {
                value.length > 0 ? clearErrors("options") : setError("options", { message: "Vui lòng nhập Trả lời!" });
                setValue("options", value);
              }}
              options={[]}
              dropdownStyle={{ display: "none" }}
            />
            {errors.options && (
              <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                {errors.options.message}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mb-3 d-flex">
        <div className="fw-bold fs-5 mb-2 d-flex align-items-center gap-3 w-50">
          <span>Trạng thái:</span>
          <div className="form-check form-check-success form-switch form-check-solid justify-content-start">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              defaultChecked={formValuesState.active}
              onChange={(e) => setValue("active", e.target.checked)}
            />
          </div>
        </div>
        <div className="fw-bold fs-5 mb-2 d-flex align-items-center gap-3 w-50">
          <span>Bắt buộc:</span>
          <div className="form-check form-check-success form-switch form-check-solid justify-content-start">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              defaultChecked={formValuesState.required}
              onChange={(e) => setValue("required", e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-end gap-2">
        <button type="button" className="btn btn-secondary btn-sm" onClick={onCancel}>
          Huỷ
        </button>
        <button type="submit" className="btn btn-primary btn-sm">
          Chỉnh sửa
        </button>
      </div>
    </form>
  );
};

export default withErrorBoundary(UpdateQuestion, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
