import React, { Fragment, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, ProjectQuestionCampaignType, ProjectQuestionType } from "../../../../types";
import { Collapse, Dropdown, Empty, Modal } from "antd";
import { useDispatch } from "react-redux";
import UpdateQuestion from "./UpdateQuestion";
import CreateQuestion from "./CreateQuestion";
import { projectAPI } from "../../../../services";
import { handleMessage } from "../../../commons/Message";
import { handleCheckAxiosError } from "../../../../utils";
import { setAccount } from "../../../../redux/slices/accountSlice";
import { setLoading } from "../../../../redux/slices/loadingSlice";

type Props = {
  projectDetail: ProjectDetailType;
  questionCampaign: ProjectQuestionCampaignType;
  onUpdateCampaignList: (questionCampaign: ProjectQuestionCampaignType) => void;
};

const QuestionList: React.FC<Props> = ({ projectDetail, questionCampaign, onUpdateCampaignList }) => {
  const dispatch = useDispatch();
  const [editedQuestion, setEditedQuestion] = useState<ProjectQuestionType | null>(null);
  const [activeKeyCollapse, setActiveKeyCollapse] = useState<number | undefined>(undefined);
  const [questionList, setQuestionList] = useState<ProjectQuestionType[]>(questionCampaign.questions);
  const [isShowCreateQuestionForm, setIsShowCreateQuestionForm] = useState(false);
  const [deleteQuestion, setDeleteQuestion] = useState<ProjectQuestionType | null>(null);

  const handleUpdateQuestionList = (newQuestionList: ProjectQuestionType[]) => {
    setQuestionList(newQuestionList);
    onUpdateCampaignList({
      ...questionCampaign,
      questions: newQuestionList,
    });
  };

  const handleDeleteQuestion = async () => {
    try {
      if (!deleteQuestion) return;
      else {
        const resultDeleteQuestionApi = await projectAPI.deleteQuestion(deleteQuestion.id);
        if (resultDeleteQuestionApi.data.result) {
          handleMessage("success", resultDeleteQuestionApi.data.msg);
          const newQuestionList = questionList.filter((item) => item.id !== deleteQuestion.id);
          handleUpdateQuestionList(newQuestionList);
          setDeleteQuestion(null);
        } else {
          handleMessage("error", resultDeleteQuestionApi.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      setDeleteQuestion(null);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="list-question">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-1">Danh sách câu hỏi khảo sát</h1>
        <div className="text-muted fw-semibold fs-5">
          Chiến dịch {questionCampaign.name} - Dự án {projectDetail.profile.code}
        </div>
      </div>
      {questionList.length === 0 ? (
        isShowCreateQuestionForm ? (
          <Collapse
            accordion={true}
            activeKey="create-question"
            collapsible="disabled"
            items={[
              {
                key: "create-question",
                label: "Create Question",
                children: (
                  <CreateQuestion
                    campaignCode={questionCampaign.code}
                    onCancel={() => setIsShowCreateQuestionForm(false)}
                    onSuccess={(newQuestion) => handleUpdateQuestionList([...questionList, newQuestion])}
                  />
                ),
                classNames: {
                  header: "text-black",
                },
              },
            ]}
          />
        ) : (
          <div className="text-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
              <button className="btn btn-primary btn-sm" onClick={() => setIsShowCreateQuestionForm(true)}>
                Thêm câu hỏi
              </button>
            </Empty>
          </div>
        )
      ) : (
        <Fragment>
          <Collapse
            accordion={true}
            onChange={(key) => setActiveKeyCollapse(Number(key))}
            activeKey={isShowCreateQuestionForm ? "create-question" : activeKeyCollapse}
            collapsible={editedQuestion || isShowCreateQuestionForm ? "disabled" : "header"}
            items={[
              ...questionList.map((question) => ({
                key: question.id,
                label: question.question,
                children:
                  question.id === editedQuestion?.id ? (
                    <UpdateQuestion
                      editedQuestion={editedQuestion}
                      onCancel={() => setEditedQuestion(null)}
                      onSuccess={(newQuestion: ProjectQuestionType) => {
                        const newQuestionList = questionList.map((item) =>
                          item.id === newQuestion.id ? newQuestion : item
                        );
                        handleUpdateQuestionList(newQuestionList);
                        setEditedQuestion(null);
                      }}
                    />
                  ) : (
                    <Fragment>
                      <div className="mb-3">
                        <div className="fw-bold fs-5 mb-2">Loại câu hỏi:</div>
                        <div className="fs-5 text-black text-opacity-75">{question.questionType}</div>
                      </div>
                      <div className="mb-3">
                        <div className="fw-bold fs-5 mb-2">Câu hỏi:</div>
                        <div className="fs-5 text-black text-opacity-75">{question.question}</div>
                      </div>
                      {question.questionType !== "INPUT" && (
                        <div className="mb-3">
                          <div className="fw-bold fs-5 mb-2">Trả lời:</div>
                          <div className="fs-5">
                            {question.options.map((item, index) => (
                              <span key={index} className="badge badge-secondary me-2 fs-6 fw-normal">
                                {item}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="mb-3 d-flex">
                        <div className="fw-bold fs-5 mb-2 d-flex align-items-center gap-3 w-50">
                          <span>Trạng thái:</span>
                          <span className={`badge badge-lg badge-light-${question.active ? "success" : "danger"}`}>
                            {question.active ? "Active" : "Inactive"}
                          </span>
                        </div>
                        <div className="fw-bold fs-5 mb-2 d-flex align-items-center gap-3 w-50">
                          <span>Bắt buộc:</span>

                          <span className={`badge badge-lg badge-light-${question.required ? "success" : "danger"}`}>
                            {question.required ? "Có" : "Không"}
                          </span>
                        </div>
                      </div>
                    </Fragment>
                  ),
                extra:
                  editedQuestion || isShowCreateQuestionForm ? null : (
                    <Dropdown
                      trigger={["hover"]}
                      dropdownRender={() => (
                        <div className="d-flex flex-column ant-dropdown-menu">
                          <button
                            className="btn text-start py-2 bg-hover-light px-3 min-w-50px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditedQuestion(question);
                              setActiveKeyCollapse(question.id);
                            }}
                          >
                            <i className="fa-solid fa-pencil"></i> Chỉnh sửa
                          </button>
                          <button
                            className="btn text-start py-2 bg-hover-light px-3 min-w-50px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteQuestion(question);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i> Xóa
                          </button>
                        </div>
                      )}
                      placement="bottomRight"
                    >
                      <i className="fa-solid fa-gear text-hover-primary" onClick={(e) => e.stopPropagation()}></i>
                    </Dropdown>
                  ),
              })),
              ...(isShowCreateQuestionForm
                ? [
                    {
                      key: "create-question",
                      label: "Create Question",
                      children: (
                        <CreateQuestion
                          campaignCode={questionCampaign.code}
                          onCancel={() => setIsShowCreateQuestionForm(false)}
                          onSuccess={(newQuestion) => handleUpdateQuestionList([...questionList, newQuestion])}
                        />
                      ),
                      classNames: {
                        header: "text-black",
                      },
                    },
                  ]
                : []),
            ]}
          />
          {!isShowCreateQuestionForm && (
            <button
              className="btn btn-icon-dark btn-text-dark btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark w-100 mt-4"
              onClick={() => setIsShowCreateQuestionForm(true)}
            >
              <i className="fa-solid fa-plus text-black"></i> Thêm câu hỏi
            </button>
          )}
        </Fragment>
      )}

      <Modal
        open={!!deleteQuestion}
        footer={null}
        onCancel={() => {
          setDeleteQuestion(null);
        }}
        maskClosable={false}
        destroyOnClose
        style={{ top: 20 }}
      >
        <div className="py-20">
          <div className="text-center mb-10">
            <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
          </div>
          <div className="text-center mb-10">
            Bạn có chắc chắn muốn xoá câu hỏi <span className="fw-bolder">{deleteQuestion?.question}</span> ?
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-light-primary mx-2" onClick={() => setDeleteQuestion(null)}>
              Huỷ
            </button>
            <button type="button" className="btn btn-light-danger mx-2" onClick={handleDeleteQuestion}>
              Tôi chắc chắn
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withErrorBoundary(QuestionList, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
