import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoading } from "../../redux/slices/loadingSlice";
import { handleMessage } from "../../components/commons/Message";
import { projectAPI } from "../../services";
import { ProjectDetailType } from "../../types";
import SettingProjectInformation from "../../components/dashboard/SettingProjectInformation";
import SettingProjectLogin from "../../components/dashboard/SettingProjectLogin";
import { setAccount } from "../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../utils";
import SettingProjectCustomer from "../../components/dashboard/SettingProjectCustomer";
import SettingProjectUser from "../../components/dashboard/SettingProjectUser";
import SettingProjectConfig from "../../components/dashboard/SettingProjectConfig";
import SettingProjectWifiProfile from "../../components/dashboard/SettingProjectWifiProfile";
import SettingProjectDevice from "../../components/dashboard/SettingProjectDevice";
import SettingProjectQuestion from "../../components/dashboard/SettingProjectQuestion";

interface tabType {
  key: number;
  title: string;
  component: React.FC<{
    projectDetail: ProjectDetailType;
    onUpdateSuccess: (data: ProjectDetailType) => void;
  }>;
}

const tabList: tabType[] = [
  {
    key: 1,
    title: "Thông tin",
    component: SettingProjectInformation,
  },
  {
    key: 2,
    title: "Đăng nhập",
    component: SettingProjectLogin,
  },
  {
    key: 3,
    title: "Cấu hình",
    component: SettingProjectConfig,
  },
  {
    key: 4,
    title: "Chiến dịch khảo sát",
    component: SettingProjectQuestion,
  },
  {
    key: 5,
    title: "Wifi profile",
    component: SettingProjectWifiProfile,
  },
  {
    key: 6,
    title: "Thiết bị",
    component: SettingProjectDevice,
  },
  {
    key: 7,
    title: "Quản trị viên",
    component: SettingProjectCustomer,
  },
  {
    key: 8,
    title: "Danh sách người dùng",
    component: SettingProjectUser,
  },
];

const ProjectDetailPage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [projectDetail, setProjectDetail] = useState<ProjectDetailType | null>(null);
  const [activeTabComponent, setActiveTabComponent] = useState<tabType>(tabList[0]);
  const [tabListShow, setTabListShow] = useState(tabList);

  const handleGetProjectDetail = async (id: string | null) => {
    try {
      dispatch(setLoading(true));
      const resultProjectDetailApi = await projectAPI.getProjectDetail(id);
      if (resultProjectDetailApi.data.result) {
        setProjectDetail(resultProjectDetailApi.data.data);
      } else {
        handleMessage("error", resultProjectDetailApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetProjectDetail(id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (projectDetail?.profile.projectStatus === "CONFIG") {
      setTabListShow(tabList.filter((item) => item.key !== 4));
    } else if (projectDetail?.profile.projectStatus === "QUESTION") {
      setTabListShow(tabList.filter((item) => item.key !== 3));
    } else {
      setTabListShow(tabList.filter((item) => item.key !== 3 && item.key !== 4));
    }
  }, [projectDetail]);

  return (
    <div className="project-detail-page">
      {projectDetail && (
        <>
          <div className="card mb-5 mb-xl-10">
            <div className="card-body m-0 pb-0">
              <h3 className="fw-bold m-0">Chi tiết dự án #{projectDetail.profile.code}</h3>
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                {tabListShow.map((item) => {
                  return (
                    <li
                      key={item.key}
                      className="nav-item mt-2"
                      onClick={() => setActiveTabComponent(item)}
                      role="button"
                    >
                      <span
                        className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                          item.key === activeTabComponent.key ? "active" : ""
                        }`}
                      >
                        {item.title}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">{activeTabComponent.title}</h3>
              </div>
            </div>
            <div className="collapse show">
              <activeTabComponent.component
                projectDetail={projectDetail}
                onUpdateSuccess={(data: ProjectDetailType) => setProjectDetail(data)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withErrorBoundary(ProjectDetailPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
