import React, { useEffect } from "react";
import { ProjectDetailType, ProjectQuestionCampaignType, ProjectQuestionCampaignEditedType } from "../../../types";
import { useForm, SubmitHandler } from "react-hook-form";
import { ColorPicker, DatePicker } from "antd";
import dayjs from "dayjs";
import ImagePreview from "../../commons/ImagePreview";
import { projectAPI, uploadAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError } from "../../../utils";
import { useDispatch } from "react-redux";
import { setAccount } from "../../../redux/slices/accountSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";

const { RangePicker } = DatePicker;

interface Props {
  projectDetail: ProjectDetailType;
  updateQuestionCampaignList: (questionCampaignData: ProjectQuestionCampaignType) => void;
  questionCampaignData: ProjectQuestionCampaignType;
}

const UpdateCampaignForm: React.FC<Props> = ({ projectDetail, updateQuestionCampaignList, questionCampaignData }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<ProjectQuestionCampaignEditedType>({
    defaultValues: {
      ...questionCampaignData,
    },
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<ProjectQuestionCampaignEditedType> = async (value) => {
    try {
      dispatch(setLoading(true));
      const resultCreateQuestionCampaign = await projectAPI.updateQuestionCampaign(value);
      if (resultCreateQuestionCampaign.data.result) {
        handleMessage("success", resultCreateQuestionCampaign.data.msg);
        updateQuestionCampaignList(resultCreateQuestionCampaign.data.data);
      } else {
        handleMessage("error", resultCreateQuestionCampaign.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) {
        setValue("banner", "");
        return;
      }
      const file = e.target.files[0];
      const resultUploadFileApi = await uploadAPI.uploadFile(file);
      if (resultUploadFileApi.data.result) {
        setValue("banner", `${resultUploadFileApi.data.data.urlImage}`);
      } else {
        handleMessage("error", resultUploadFileApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    }
  };

  useEffect(() => {
    register("startDate", { required: "Vui lòng chọn thời gian chiến dịch!" });
    register("endDate", { required: "Vui lòng chọn thời gian chiến dịch!" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="add-campaign-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-1">Cập nhật chiến dịch khảo sát</h1>
        <div className="text-muted fw-semibold fs-5">Cập nhật chiến dịch vào dự án {projectDetail.profile.code}</div>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên chiến dịch</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.name && "border-danger"}`}
                  placeholder="Tên chiến dịch"
                  {...register("name", {
                    required: "Vui lòng nhập Tên chiến dịch!",
                  })}
                  onBlur={() => setValue("name", formValuesState.name?.trim())}
                />
                {errors.name && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.name.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Thời gian chiến dịch</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="DD/MM/YYYY HH:mm"
                  allowClear={false}
                  defaultValue={[dayjs.unix(formValuesState.startDate), dayjs.unix(formValuesState.endDate)]}
                  className="form-control form-control-lg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Màu nền</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <ColorPicker
                  showText
                  value={formValuesState.bgColor}
                  onChange={(value) => setValue("bgColor", value.toHexString())}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Màu chính</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <ColorPicker
                  showText
                  value={formValuesState.primaryColor}
                  onChange={(value) => setValue("primaryColor", value.toHexString())}
                />
                <div className="form-text">Màu chữ, màu button.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Banner</label>
          <div className="col-lg-8">
            <div
              className={`image-input image-input-outline ${formValuesState.banner === "" ? "image-input-empty" : ""}`}
              data-kt-image-input="true"
              style={{
                background: "url(/assets/icons/landscape.svg) no-repeat center center / contain",
              }}
            >
              <div className="image-input-wrapper w-125px h-125px">
                <ImagePreview src={formValuesState.banner} className="w-100 h-100 object-fit-contain" />
              </div>
              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
              >
                <i className="fa-solid fa-pencil"></i>
                <input type="file" input-name="background" accept=".png, .jpg, .jpeg" onChange={handleUploadFile} />
              </label>
            </div>
            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
            Hiển thị tất cả câu hỏi trên màn hình
          </label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <div className="form-check form-check-success form-switch form-check-custom form-check-solid justify-content-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formValuesState.showMultipleQuestion}
                    onChange={(e) => setValue("showMultipleQuestion", e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Cho phép người dùng bỏ qua</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <div className="form-check form-check-success form-switch form-check-custom form-check-solid justify-content-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={!formValuesState.required}
                    onChange={(e) => setValue("required", !formValuesState.required)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button type="reset" className="btn btn-secondary me-3" onClick={() => reset()}>
              Reset
            </button>
            <button type="submit" className="btn btn-primary">
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateCampaignForm;
