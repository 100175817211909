import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, ProjectDeviceType } from "../../../types";
import { Empty, Modal, Tooltip } from "antd";
import CreateDeviceForm from "./CreateDeviceForm";
import UpdateDeviceForm from "./UpdateDeviceForm";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import "./index.scss";
import TransferDeviceProjectForm from "./TransferDeviceProjectForm";
import { getAccountSelector } from "../../../redux/selectors";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

const SettingProjectDevice: React.FC<Props> = ({ projectDetail, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountSelector);
  const deviceList = projectDetail.devices;
  const [isShowCreateDeviceForm, setIsShowCreateDeviceForm] = useState(false);
  const [updatedDevice, setUpdatedDevice] = useState<ProjectDeviceType | null>(null);
  const [deletedDevice, setDeletedDevice] = useState<ProjectDeviceType | null>(null);
  const [transferDevice, setTransferDevice] = useState<ProjectDeviceType | null>(null);

  const handleUpdateProjectDetail = (type: "create" | "update" | "delete", device: ProjectDeviceType) => {
    let newProjectDeviceList: ProjectDeviceType[] = [];
    let newProjectDetail: ProjectDetailType;
    switch (type) {
      case "create":
        newProjectDeviceList = [...projectDetail.devices, device];
        newProjectDetail = { ...projectDetail, devices: newProjectDeviceList };
        onUpdateSuccess(newProjectDetail);
        setIsShowCreateDeviceForm(false);
        break;
      case "update":
        newProjectDeviceList = projectDetail.devices.map((item) => {
          if (item.id === device.id) {
            return device;
          } else return item;
        });
        newProjectDetail = { ...projectDetail, devices: newProjectDeviceList };
        onUpdateSuccess(newProjectDetail);
        setUpdatedDevice(null);
        break;
      case "delete":
        newProjectDeviceList = projectDetail.devices.filter((item) => item.id !== device.id);
        newProjectDetail = { ...projectDetail, devices: newProjectDeviceList };
        onUpdateSuccess(newProjectDetail);
        setDeletedDevice(null);
        setTransferDevice(null);
        break;
      default:
    }
  };

  const handleDeleteProjectDevice = async () => {
    try {
      dispatch(setLoading(true));
      if (deletedDevice) {
        const resultDeleteWifiProfileApi = await projectAPI.deleteDevice(
          projectDetail.profile.projectId,
          deletedDevice.id
        );
        handleUpdateProjectDetail("delete", deletedDevice);
        if (resultDeleteWifiProfileApi.data.result) {
          handleMessage("success", resultDeleteWifiProfileApi.data.msg);
        } else {
          handleMessage("error", resultDeleteWifiProfileApi.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleConnectProjectDevice = async (deviceId: string, connected: boolean, projectId: string) => {
    try {
      dispatch(setLoading(true));
      const resultConnectProjectDeviceApi = await projectAPI.connectDevice(deviceId, connected, projectId);
      if (resultConnectProjectDeviceApi.data.result) {
        handleUpdateProjectDetail("update", resultConnectProjectDeviceApi.data.data);
        handleMessage("success", resultConnectProjectDeviceApi.data.msg);
      } else {
        handleMessage("error", resultConnectProjectDeviceApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      setDeletedDevice(null);
    }
  };

  return (
    <div className="setting-project-device">
      <div className="card-body border-top p-9">
        <div className="card-toolbar d-flex justify-content-between flex-wrap">
          <div className="position-relative my-1 ms-auto">
            <button className="btn btn-primary btn-sm" onClick={() => setIsShowCreateDeviceForm(true)}>
              Thêm thiết bị
            </button>
          </div>
        </div>
        <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-5">
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer"
              id="kt_table_widget_4_table"
            >
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th className="text-start min-w-150px sorting_disabled">identity</th>
                  <th className="text-start min-w-150px sorting_disabled">MAC</th>
                  <th className="text-center min-w-100px sorting_disabled">IP</th>
                  <th className="text-center min-w-75px sorting_disabled">Port</th>
                  <th className="text-center min-w-100px sorting_disabled">Status</th>
                  <th className="text-end min-w-150px sorting_disabled">Server name</th>
                  <th className="min-w-150px"></th>
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                {deviceList.length === 0 ? (
                  <tr>
                    <td colSpan={7}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  deviceList.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-start">{item.identity}</td>
                        <td className="text-start">{item.mac}</td>
                        <td className="text-center">{item.ip}</td>
                        <td className="text-center">{item.port}</td>
                        <td className="text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="form-check form-switch form-check-custom justify-content-center mx-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={item.connected}
                                onChange={(e) => handleConnectProjectDevice(item.id, e.target.checked, item.projectId)}
                              />
                            </div>
                            <Tooltip title={item.connected ? item.lastTime : item.msgError}>
                              <img src="/assets/icons/info.svg" alt="" width={15} className="img-muted" />
                            </Tooltip>
                          </div>
                        </td>
                        <td className="text-end">{item.serverName}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-icon btn-sm btn-primary me-2"
                            onClick={() => setUpdatedDevice(item)}
                          >
                            <i className="fa-regular fa-pen-to-square fs-2"></i>
                          </button>
                          {account?.role === "ROLE_ADMIN" && (
                            <button
                              className="btn btn-icon btn-sm btn-warning me-2"
                              onClick={() => setTransferDevice(item)}
                            >
                              <i className="fa-solid fa-arrow-right-arrow-left"></i>
                            </button>
                          )}
                          <button className="btn btn-icon btn-sm btn-danger" onClick={() => setDeletedDevice(item)}>
                            <i className="fa-regular fa-trash-can fs-2"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        {deviceList.length > 0 && (
          <div>
            <p className="text-warning fw-bolder mb-1 fs-5">Lưu ý:</p>
            <p className="mb-1">Nếu thiết bị ngắt kết nối thì đăng nhập với tài khoản và mật khẩu mặc định sau:</p>
            <div className="mb-1 ps-2">
              <p className="mb-0">
                - Username:&nbsp;
                <span className="fw-bold">{projectDetail.profile.usernameDefault}</span>
              </p>
              <p className="mb-0">
                - Password:&nbsp;
                <span className="fw-bold">{projectDetail.profile.passwordDefault}</span>
              </p>
            </div>
          </div>
        )}
      </div>

      <Modal
        open={isShowCreateDeviceForm || !!updatedDevice || !!deletedDevice || !!transferDevice}
        maskClosable={false}
        onCancel={() => {
          setIsShowCreateDeviceForm(false);
          setUpdatedDevice(null);
          setDeletedDevice(null);
          setTransferDevice(null);
        }}
        footer={null}
        destroyOnClose
        style={{ top: 10 }}
      >
        {updatedDevice ? (
          <UpdateDeviceForm
            device={updatedDevice}
            projectDetail={projectDetail}
            onSuccess={(newDevice) => handleUpdateProjectDetail("update", newDevice)}
          />
        ) : deletedDevice ? (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn xoá device <span className="fw-bolder">{deletedDevice.identity}</span> ?
            </div>
            <div className="text-center">
              <button type="button" className="btn btn-light-primary mx-2" onClick={() => setDeletedDevice(null)}>
                Huỷ
              </button>
              <button type="button" className="btn btn-light-danger mx-2" onClick={handleDeleteProjectDevice}>
                Tôi chắc chắn
              </button>
            </div>
          </div>
        ) : transferDevice ? (
          <TransferDeviceProjectForm
            device={transferDevice}
            projectDetail={projectDetail}
            onSuccess={(newDevice) => handleUpdateProjectDetail("delete", newDevice)}
          />
        ) : (
          <CreateDeviceForm
            projectDetail={projectDetail}
            onSuccess={(newDevive: ProjectDeviceType) => handleUpdateProjectDetail("create", newDevive)}
          />
        )}
      </Modal>
    </div>
  );
};

export default withErrorBoundary(SettingProjectDevice, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
