import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, ProjectQuestionCampaignEditedType, ProjectQuestionCampaignType } from "../../../types";
import { Empty, Modal } from "antd";
import { useDispatch } from "react-redux";
import { projectAPI } from "../../../services";
import { convertUnixTimeToTime, handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleMessage } from "../../commons/Message";
import AddCampaignForm from "./AddCampaignForm";
import UpdateCampaignForm from "./UpdateCampaignForm";
import QuestionList from "./QuestionList";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

type ActionType =
  | {
      type: "delete" | "update" | "questionList";
      questionCampaign: ProjectQuestionCampaignType;
    }
  | { type: "create" };

const SettingProjectQuestion: React.FC<Props> = ({ projectDetail }) => {
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState<ProjectQuestionCampaignType[]>([]);
  const [action, setAction] = useState<ActionType | null>(null);

  const handleUpdateCampaignList = (
    type: "create" | "delete" | "update",
    questionCampaign: ProjectQuestionCampaignType
  ) => {
    if (type === "create") {
      setCampaignList([...campaignList, questionCampaign]);
    } else if (type === "update") {
      setCampaignList((prev) => prev.map((item) => (item.id === questionCampaign.id ? questionCampaign : item)));
    } else {
      setCampaignList(campaignList.filter((item) => item.id !== questionCampaign.id));
    }
    action && action.type !== "questionList" && setAction(null);
  };

  const handleEditQuestionCampaign = async (campaign: ProjectQuestionCampaignEditedType) => {
    try {
      dispatch(setLoading(true));
      const resultEditQuestionCampaignApi = await projectAPI.updateQuestionCampaign(campaign);
      if (resultEditQuestionCampaignApi.data.result) {
        handleMessage("success", resultEditQuestionCampaignApi.data.msg);
        handleUpdateCampaignList("update", resultEditQuestionCampaignApi.data.data);
      } else {
        handleMessage("error", resultEditQuestionCampaignApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteQuestionCampaign = async (campaign: ProjectQuestionCampaignType) => {
    try {
      dispatch(setLoading(true));
      const resultDeleteQuestionCampaignApi = await projectAPI.deleteQuestionCampaign(campaign.code);
      if (resultDeleteQuestionCampaignApi.data.result) {
        handleMessage("success", resultDeleteQuestionCampaignApi.data.msg);
        handleUpdateCampaignList("delete", campaign);
      } else {
        handleMessage("error", resultDeleteQuestionCampaignApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const handleGetCampaignList = async () => {
      try {
        dispatch(setLoading(true));
        const resultGetCampaignListApi = await projectAPI.getCampaignQuestionList(projectDetail.profile.projectId);
        if (resultGetCampaignListApi.data.result) {
          setCampaignList(resultGetCampaignListApi.data.data);
        } else {
          handleMessage("error", resultGetCampaignListApi.data.msg);
        }
      } catch (error) {
        handleCheckAxiosError(error, () => dispatch(setAccount(null)));
      } finally {
        dispatch(setLoading(false));
      }
    };
    handleGetCampaignList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="setting-project-device">
      <div className="card-body border-top p-9">
        <div className="card-toolbar d-flex justify-content-between flex-wrap">
          <div className="position-relative my-1 ms-auto">
            <button className="btn btn-primary btn-sm" onClick={() => setAction({ type: "create" })}>
              Thêm chiến dịch
            </button>
          </div>
        </div>
        <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-5">
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer"
              id="kt_table_widget_4_table"
            >
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th className="text-start min-w-200px sorting_disabled">Tên chiến dịch</th>
                  <th className="text-start min-w-150px sorting_disabled">Ngày bắt đầu</th>
                  <th className="text-center min-w-150px sorting_disabled">Ngày kết thúc</th>
                  <th className="text-center min-w-100px sorting_disabled">Trạng thái</th>
                  <th className="min-w-150px"></th>
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                {campaignList.length === 0 ? (
                  <tr>
                    <td colSpan={7}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  campaignList.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-start">{item.name}</td>
                        <td className="text-start">{convertUnixTimeToTime(item.startDate)}</td>
                        <td className="text-center">{convertUnixTimeToTime(item.endDate)}</td>
                        <td className="text-center">
                          <div className="form-check form-check-success form-switch form-check-custom form-check-solid justify-content-center">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              checked={item.active}
                              onChange={(e) => handleEditQuestionCampaign({ ...item, active: e.target.checked })}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-icon btn-sm btn-primary me-2"
                            onClick={() => setAction({ type: "update", questionCampaign: item })}
                          >
                            <i className="fa-regular fa-pen-to-square fs-2"></i>
                          </button>
                          <button
                            className="btn btn-icon btn-sm btn-warning me-2"
                            onClick={() => setAction({ type: "questionList", questionCampaign: item })}
                          >
                            <i className="fa-solid fa-list-ul fs-2"></i>
                          </button>
                          <button
                            className="btn btn-icon btn-sm btn-danger"
                            onClick={() => setAction({ type: "delete", questionCampaign: item })}
                          >
                            <i className="fa-regular fa-trash-can fs-2"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        open={!!action}
        footer={null}
        onCancel={() => {
          setAction(null);
        }}
        maskClosable={false}
        className="setting-project-user__action-modal"
        destroyOnClose
        style={{ top: 20 }}
      >
        {action && action.type === "create" && (
          <AddCampaignForm
            projectDetail={projectDetail}
            updateQuestionCampaignList={(data) => handleUpdateCampaignList("create", data)}
          />
        )}
        {action && action.type === "update" && action.questionCampaign && (
          <UpdateCampaignForm
            projectDetail={projectDetail}
            updateQuestionCampaignList={(questionCampaignData) =>
              handleUpdateCampaignList("update", questionCampaignData)
            }
            questionCampaignData={action.questionCampaign}
          />
        )}
        {action && action.type === "delete" && action.questionCampaign && (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn xoá chiến dịch <span className="fw-bolder">{action.questionCampaign.name}</span> ?
            </div>
            <div className="text-center">
              <button type="button" className="btn btn-light-primary mx-2" onClick={() => setAction(null)}>
                Huỷ
              </button>
              <button
                type="button"
                className="btn btn-light-danger mx-2"
                onClick={() => handleDeleteQuestionCampaign(action.questionCampaign)}
              >
                Tôi chắc chắn
              </button>
            </div>
          </div>
        )}
        {action && action.type === "questionList" && action.questionCampaign && (
          <QuestionList
            projectDetail={projectDetail}
            questionCampaign={action.questionCampaign}
            onUpdateCampaignList={(questionCampaign) => handleUpdateCampaignList("update", questionCampaign)}
          />
        )}
      </Modal>
    </div>
  );
};

export default withErrorBoundary(SettingProjectQuestion, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
